const links = [
    {
        label: "Início",
        url: "/"
    },
    {
        label: "Sobre mim",
        url: "/sobre-mim/",
    },
    {
        label: "Contato",
        url: "/contato/",
    }
]

export default links