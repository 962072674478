const links = [
    { 
        label: 'Instagram',
        url: 'https://www.instagram.com/FelipeSaturnino_',
    },
    {
        label: 'Twitter',
        url: 'https://twitter.com/FeSaturnino_',
    },
    {
        label: 'Linkedin',
        url: 'https://www.linkedin.com/in/FelipeSaturnino',
    },
    {
        label: 'Youtube',
        url: 'https://www.youtube.com/channel/UCwT3N_1WE8z33UOfWJ9NLdQ',
    },
]

export default links